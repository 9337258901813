<template>
  <div class="col">
    <!-- Start Design For Print -->
    <div class="the_main_container_for_print_all_pdf bg-white">
      <div class="main_container">
        <!-- // Start User Details Large Screens  -->
        <div style="width: 100% " class="my-4 main_use_ur_pattern_container_lg">
          <card class="">
            <div class="main_auth_user_info">
              <div>
                <span class="d-block mb-2 n_name">{{
                  `${user2.first_name} ${user2.last_name}`
                }}</span>
                <span class="d-block n_email"> {{ user2.email }}</span>
              </div>
              <div class="classification_classification">
                <span
                  class="classification"
                  v-if="user2.classification == 'Bridge'"
                  >{{ localization("user type bridge") }}</span
                >
                <span
                  class="classification"
                  v-if="user2.classification == 'Dynamic'"
                  >{{ localization("User type dynamic") }}</span
                >
                <span
                  class="classification"
                  v-if="user2.classification == 'Strong-Willed Learner'"
                  >{{ localization("user type SWL") }}</span
                >
              </div>
            </div>
            <div class="which_user_type mt-2 mb-3">
              <div class="nn">
                <div class="another_content">
                  <span
                    class="tit text-uppercase"
                    v-if="user2.classification == 'Bridge'"
                    >{{ localization("user type bridge") }}
                    {{ localization("definition") }}
                  </span>
                  <span
                    class="tit text-uppercase"
                    v-if="user2.classification == 'Dynamic'"
                    >{{ localization("User type dynamic") }}
                    {{ localization("definition") }}</span
                  >
                  <span
                    class="tit text-uppercase"
                    v-if="user2.classification == 'Strong-Willed Learner'"
                    >{{ localization("user type SWL") }}
                    {{ localization("definition") }}</span
                  >
                  <!-- Description Text  -->

                  <span
                    class="tex d-block"
                    v-if="user2.classification == 'Bridge'"
                  >
                    {{ localization("bridge tool tip") }}
                  </span>
                  <span
                    class="tex d-block"
                    v-if="user2.classification == 'Dynamic'"
                  >
                    {{ localization("dynamic tool tip") }}
                  </span>
                  <span
                    class="tex d-block"
                    v-if="user2.classification == 'Strong-Willed Learner'"
                  >
                    {{ localization("swl to tip") }}
                  </span>
                </div>
                <!-- Dynamic -->
                <!-- Strong-Willed Learner -->
              </div>
            </div>
            <div class="row my-4  main-card-print">
              <div
                class="col-sm-12 px-0 px-lg-3 d-flex align-items-center the_patterns_with_new_theme"
              >
                <!-- Start Sequence  -->
                <div style="min-width: 25%;">
                  <card
                    :height="`${userScore.sequence * 2.5 + 120}px`"
                    style="
              background-color: #39c;
              border-radius: 0;

              width: 100%;
              max-height: 230px;
              min-height: 120px;
            "
                    class="my-auto score"
                  >
                    <div class="d-flex flex-column my-auto">
                      <!-- score title text -->
                      <!-- Medium & larger screens text   -->
                      <p
                        class="mt-1 text-center text-white mb-0"
                        style="font-size: 2rem"
                      >
                        {{ localization("Sequence") }}
                      </p>
                      <div
                        class="
                  d-flex
                  align-items-center align-items-xxl-start
                  justify-content-center justify-content-lg-around
                "
                      >
                        <!-- First Letter  -->
                        <p class="letter mb-0 text-white">
                          {{ localization("Sequence shortcut") }}
                        </p>
                        <!-- Score Number  -->

                        <!-- Large Screens  -->
                        <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 50 is min font value -->
                        <p
                          class="number mb-0 text-white"
                          :style="{
                            fontSize: (userScore.sequence / 28) * 25 + 65 + 'px'
                          }"
                        >
                          {{
                            userScore.sequence != null ? userScore.sequence : 0
                          }}
                        </p>
                      </div>
                    </div>
                  </card>
                  <div class="new_theme_pattern_content">
                    <div class="main_content_container with_sequence">
                      <span class="d-block text-uppercase ti">{{
                        localization("SEQUENTIAL LEARNING PATTERNS")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Organizes life")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Makes Lists")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Lives by schedules")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Makes extensive plans")
                      }}</span>
                    </div>
                  </div>
                </div>
                <!-- End Sequence  -->

                <!-- Start Precision   -->
                <div style="min-width: 25%;">
                  <card
                    :height="`${userScore.precision * 2.5 + 120}px`"
                    style="
              border-radius: 0;

              min-width: 25%;
              background-color: #3c3;
              max-height: 230px;
              min-height: 120px;
            "
                    class="my-auto score"
                  >
                    <div class="d-flex flex-column my-auto">
                      <!-- score title text -->

                      <!-- Medium & larger screens text   -->
                      <p
                        class="mt-1 text-center text-white mb-0"
                        style="font-size: 2rem"
                      >
                        {{ localization("precision") }}
                      </p>

                      <div
                        class="
                  d-flex
                  align-items-center align-items-xxl-start
                  justify-content-center justify-content-lg-around
                "
                      >
                        <!-- First Letter  -->
                        <p class="letter mb-0 text-white">
                          {{ localization("precision shortcut") }}
                        </p>
                        <!-- Score Number  -->
                        <!-- Large Screens  -->
                        <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                        <p
                          class="number mb-0 text-white"
                          :style="{
                            fontSize:
                              (userScore.precision / 28) * 25 + 65 + 'px'
                          }"
                        >
                          {{
                            userScore.precision != null
                              ? userScore.precision
                              : 0
                          }}
                        </p>
                      </div>
                    </div>
                  </card>
                  <div class="new_theme_pattern_content">
                    <div class="main_content_container with_precision">
                      <span class="d-block text-uppercase ti">{{
                        localization("PRECISION LEARNING PATTERNS")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Relishes information")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Seeks facts")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Must be right")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Provides detailed responses")
                      }}</span>
                    </div>
                  </div>
                </div>
                <!-- End Precision   -->
                <!-- Start Technical Reasoning  -->
                <div style="min-width: 25%;">
                  <!-- border: 1px solid #ccc; -->
                  <card
                    :height="`${userScore.technical_reasoning * 2.5 + 120}px`"
                    style="
                border-radius: 0;

                min-width: 25%;
                background-color: #f93;
                max-height: 230px;
                min-height: 120px;
              "
                    class="my-auto score"
                  >
                    <div class="d-flex flex-column my-auto">
                      <!-- score title text -->
                      <!-- Medium & larger screens text   -->
                      <p
                        class="mt-1 text-center text-white mb-0"
                        style="font-size: 1.6rem"
                      >
                        {{ localization("Technical Reasoning") }}
                      </p>
                      <div
                        class="
                    d-flex
                    align-items-center align-items-xxl-start
                    justify-content-center justify-content-lg-around

                  "
                      >
                        <!-- First Letter  -->
                        <p class="letter mb-0 text-white">
                          {{ localization("Technical Reasoning shortcut") }}
                        </p>
                        <!-- Score Number  -->
                        <!-- Large Screens  -->
                        <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                        <p
                          class="number mb-0 text-white"
                          :style="{
                            fontSize:
                              (userScore.technical_reasoning / 28) * 25 +
                              65 +
                              'px'
                          }"
                        >
                          {{
                            userScore.technical_reasoning != null
                              ? userScore.technical_reasoning
                              : 0
                          }}
                        </p>
                      </div>
                    </div>
                  </card>
                  <div class="new_theme_pattern_content">
                    <div
                      class="main_content_container with_technical_reasoning"
                    >
                      <span class="d-block text-uppercase ti">{{
                        localization("TECHNICAL LEARNING PATTERNS")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Solves problems")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Uses few words")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Looks for relevance and practicality")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Works alone")
                      }}</span>
                    </div>
                  </div>
                </div>
                <!-- End Technical Reasoning  -->
                <!--Start Confluence  -->
                <div style="min-width: 25%;">
                  <card
                    :height="`${userScore.confluence * 2.5 + 120}px`"
                    style="
                    border-radius: 0;

                    min-width: 25%;
                    background-color: #c36;
                    max-height: 230px;
                    min-height: 120px;
                  "
                    class="my-auto score"
                  >
                    <div class="d-flex flex-column my-auto">
                      <!-- score title text -->
                      <!-- Medium & larger screens text   -->
                      <p
                        class="mt-1 text-center text-white mb-0"
                        style="font-size: 2rem"
                      >
                        {{ localization("Confluence") }}
                      </p>

                      <div
                        class="
                        d-flex
                        align-items-center align-items-xxl-start
                        justify-content-center justify-content-lg-around
                      "
                      >
                        <!-- First Letter  -->
                        <p class="letter mb-0 text-white">
                          {{ localization("Confluence shortcut") }}
                        </p>
                        <!-- Score Number  -->
                        <!-- Large Screens  -->
                        <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                        <p
                          class="number mb-0 text-white"
                          :style="{
                            fontSize:
                              (userScore.confluence / 28) * 25 + 65 + 'px'
                          }"
                        >
                          {{
                            userScore.confluence != null
                              ? userScore.confluence
                              : 0
                          }}
                        </p>
                      </div>
                    </div>
                  </card>
                  <div class="new_theme_pattern_content">
                    <div class="main_content_container with_confluence">
                      <span class="d-block text-uppercase ti">{{
                        localization("CONFLUENCE LEARNING PATTERNS")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Takes risks")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Sees the big picture")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Embraces innovation")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Initiates change")
                      }}</span>
                    </div>
                  </div>
                </div>
                <!--End Confluence  -->
              </div>

              <!-- Start New Section  -->
              <div class="wrapper_for_use_first col-sm-12 mt-5 px-0 px-lg-3">
                <div class="main_co">
                  <div class="a_one with_title">
                    <span class="text-uppercase">{{
                      localization("DEGREE OF USE")
                    }}</span>
                    <span>:</span>
                  </div>
                  <div class="a_one ">
                    <span class="text-capitalize">{{
                      localization("Use First")
                    }}</span>
                    <span>:</span>
                    <span class="text-uppercase">35 - 25</span>
                  </div>
                  <div class="a_one a_two">
                    <span class="text-capitalize">{{
                      localization("As Needed")
                    }}</span>
                    <span>:</span>
                    <span class="text-uppercase">24 - 18</span>
                  </div>
                  <div class="a_one ">
                    <span class="text-capitalize">{{
                      localization("Avoid")
                    }}</span>
                    <span>:</span>
                    <span class="text-uppercase">17 - 7</span>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
        <!-- // End User Details Large Screens  -->
        <!-- Start Two Strategy Cards   -->
        <div class="mt-4 mb-4 main_strategy_container">
          <div
            class="cms_strategy_wrapper"
            v-if="careerMatchBoxes[0].body != null"
          >
            <div v-html="careerMatchBoxes[0].body"></div>
          </div>
          <div
            class="cms_strategy_wrapper"
            v-if="careerMatchBoxes[1].body != null"
          >
            <div v-html="careerMatchBoxes[1].body"></div>
          </div>
          <div
            class="cms_strategy_wrapper"
            v-if="careerMatchBoxes[2].body != null"
          >
            <div v-html="careerMatchBoxes[2].body"></div>
          </div>
        </div>
        <!-- End Two Strategy Cards   -->
        <!-- Start Every Task -->
        <div class="every_task_with_us">
          <div
            style="width: 100%"
            class="print-page-break looping_tasks_here"
            v-for="task in getUserCareerTasks"
            :key="task.id"
          >
            <card>
              <template #header>
                <div
                  class="
                  head_of_tasks
                "
                >
                  <h5 class="mb-2 the_task_name fs-3">
                    <i class="fa fa-tasks mr-2"></i>
                    <span>{{ task.name }}</span>

                    <span class="text-muted">( {{ task.topic_name }} )</span>
                  </h5>
                </div>
                <p
                  class="pl-2 str_tsk_desc"
                  style="
                  background: #aaaaaa;
                  padding: 5px;
                  color: #fff;
                  border-radius: 5px;
                "
                  :class="{ 'text-right': isRtl() }"
                >
                  {{ localization("Personal strategy description") }}
                </p>
                <!-- Task Description  -->
                <p
                  :class="{ 'text-right': isRtl() }"
                  class="mt-2 for-task-description"
                  style="line-height: 2.1"
                  v-html="task.description"
                ></p>
              </template>

              <!-- Start Collapse -->
              <el-collapse class="mt-2" v-model="panel" multiple>
                <div
                  class="child"
                  v-for="(item, i) in task.strategy_id"
                  :key="i"
                >
                  <el-collapse-item
                    :name="`${i}`"
                    multiple
                    :class="{ 'print-page-break': i == 'Precision' }"
                  >
                    <!-- //! start  collapse title -->
                    <template #title>
                      <div
                        class="main_collapse_title_wrapper "
                        style="width: 100%"
                        :class="{ is_rtl: isRtl() }"
                      >
                        <!-- mb-1 mt-2 isRtl() -->
                        <div>
                          <h4 class="every_collapse_title">
                            <i
                              :class="`fa fa-star mr-2 ${i}`"
                              :style="`color: ${getProgressColor(i)}`"
                            ></i>

                            {{ localization(i) }}
                          </h4>
                        </div>
                        <span class="">
                          <button
                            class="btn btn-social btn-round every_collapse_button"
                            :style="
                              `border-color:${getProgressColor(
                                i
                              )};  background: ${getProgressColor(i)}`
                            "
                          >
                            {{ getProgressValue(i) }}
                          </button>
                          <a
                            v-tooltip.top-center="
                              `${localization('Add strategy')}`
                            "
                            class="btn btn-default btn-link btn-md"
                          >
                            <i
                              class="fa fa-plus-circle fa-lg mb-4 mt-4"
                              :style="`color: ${getProgressColor(i)}`"
                            ></i>
                          </a>
                        </span>
                      </div>
                    </template>
                    <!-- //! end  collapse title -->
                    <div class="p-2 set_bg_white_for_share">
                      <audio controls id="audio" class="d-none">
                        <source id="audio-source" type="audio/mp3" />
                      </audio>
                      <div
                        v-if="item.length > 0"
                        :style="
                          `opacity:0.8;background-color : ${getProgressColor(
                            i
                          )}`
                        "
                        class="mt-2 handler_strategy_style"
                      >
                        <div
                          class="handler_speech_wrapper"
                          v-for="(feedback, i) in item"
                          :key="i"
                        >
                          <div>
                            {{ feedback.strategy }}
                          </div>
                          <i
                            v-tooltip.top-center="
                              `${localization('Read out loud')}`
                            "
                            @click="text_speech_audio(feedback.strategy)"
                            class="fa fa-volume-up icons_style speech"
                            style="cursor: pointer"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </el-collapse-item>
                </div>
              </el-collapse>
              <!-- End Collapse -->
            </card>
          </div>
        </div>
        <!-- End Every Task -->
      </div>
    </div>
    <!-- End Design For Print -->
    <div>
      <div v-if="hasPermission('access_tasks')" class="hide_me_printable">
        <!-- //? start part one ------- hideScoreAndInstruction is for importing this component in edit profile dialog at users page  -->
        <div
          v-if="!hideScoreAndInstructions"
          class="my-4"
          style="margin-left: -15px; width: calc(100% + 30px)"
        >
          <!--//?  Start Score Cards  -->
          <!-- //todo:  Start Medium & Small Screens  -->
          <div class="my-4 d-lg-none">
            <div class="col-sm-12 d-flex justify-content-center flex-column">
              <!-- //? Sequence  -->
              <div class="d-flex align-items-center">
                <p class="label sequence">
                  {{ userScore.sequence != null ? userScore.sequence : 0 }}
                </p>
                <span style="font-size: 1.3rem; text-transform: capitalize">{{
                  localization("Sequence")
                }}</span>
              </div>
              <!-- //? precision  -->
              <div class="d-flex align-items-center">
                <p class="label precision">
                  {{ userScore.precision != null ? userScore.precision : 0 }}
                </p>
                <span style="font-size: 1.3rem; text-transform: capitalize">{{
                  localization("precision")
                }}</span>
              </div>
              <!-- //? Technical Reasoning  -->
              <div class="d-flex align-items-center">
                <p class="label technical_reasoning">
                  {{
                    userScore.technical_reasoning != null
                      ? userScore.technical_reasoning
                      : 0
                  }}
                </p>
                <span style="font-size: 1.3rem">{{
                  localization("Technical Reasoning")
                }}</span>
              </div>
              <!-- //? Confluence  -->
              <div class="d-flex align-items-center">
                <p class="label confluence">
                  {{ userScore.confluence != null ? userScore.confluence : 0 }}
                </p>
                <span style="font-size: 1.3rem; text-transform: capitalize">{{
                  localization("Confluence")
                }}</span>
              </div>
            </div>
          </div>
          <!-- //todo: End Large Screens  -->

          <div class="my-4 d-none d-lg-block">
            <div class="col-sm-12 px-0 px-lg-3 d-flex align-items-center">
              <!-- Sequence  -->
              <card
                :height="`${userScore.sequence * 2.5 + 120}px`"
                style="
                background-color: #39c;
                border-radius: 0;
                border: 1px solid #ccc;
                width: 100%;
                max-height: 230px;
                min-height: 120px;
              "
                class="my-auto score"
              >
                <div class="d-flex flex-column my-auto">
                  <!-- score title text -->

                  <!-- Medium & larger screens text   -->
                  <p
                    class="mt-1 text-center text-white mb-0"
                    style="font-size: 2rem"
                  >
                    {{ localization("Sequence") }}
                  </p>
                  <div
                    class="
                    d-flex
                    align-items-center align-items-xxl-start
                    justify-content-center justify-content-lg-around
                  "
                  >
                    <!-- First Letter  -->
                    <p class="letter mb-0 text-white">
                      {{ localization("Sequence shortcut") }}
                    </p>
                    <!-- Score Number  -->
                    <!-- Small & medium Screens  -->

                    <!-- Large Screens  -->
                    <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 50 is min font value -->
                    <p
                      class="number mb-0 text-white"
                      :style="{
                        fontSize: (userScore.sequence / 28) * 25 + 65 + 'px'
                      }"
                    >
                      {{ userScore.sequence }}
                    </p>
                  </div>
                </div>
              </card>
              <!-- Precision   -->
              <card
                :height="`${userScore.precision * 2.5 + 120}px`"
                style="
                border-radius: 0;
                border: 1px solid #ccc;
                min-width: 25%;
                background-color: #3c3;
                max-height: 230px;
                min-height: 120px;
              "
                class="my-auto score"
              >
                <div class="d-flex flex-column my-auto">
                  <!-- score title text -->

                  <!-- Medium & larger screens text   -->
                  <p
                    class="mt-1 text-center text-white mb-0"
                    style="font-size: 2rem"
                  >
                    {{ localization("precision") }}
                  </p>

                  <div
                    class="
                    d-flex
                    align-items-center align-items-xxl-start
                    justify-content-center justify-content-lg-around
                  "
                  >
                    <!-- First Letter  -->
                    <p class="letter mb-0 text-white">
                      {{ localization("precision shortcut") }}
                    </p>
                    <!-- Score Number  -->
                    <!-- Large Screens  -->
                    <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                    <p
                      class="number mb-0 text-white"
                      :style="{
                        fontSize: (userScore.precision / 28) * 25 + 65 + 'px'
                      }"
                    >
                      {{ userScore.precision }}
                    </p>
                  </div>
                </div>
              </card>
              <!-- Technical Reasoning  -->
              <card
                :height="`${userScore.technical_reasoning * 2.5 + 120}px`"
                style="
                border-radius: 0;
                border: 1px solid #ccc;
                min-width: 25%;
                background-color: #f93;
                max-height: 230px;
                min-height: 120px;
              "
                class="my-auto score"
              >
                <div class="d-flex flex-column my-auto">
                  <!-- score title text -->
                  <!-- Medium & larger screens text   -->
                  <p
                    class="mt-1 text-center text-white mb-0"
                    style="font-size: 1.6rem"
                  >
                    {{ localization("Technical Reasoning") }}
                  </p>
                  <div
                    class="
                    d-flex
                    align-items-center align-items-xxl-start
                    justify-content-center justify-content-lg-around
                    flex-column flex-md-row
                  "
                  >
                    <!-- First Letter  -->
                    <p class="letter mb-0 text-white">
                      {{ localization("Technical Reasoning shortcut") }}
                    </p>
                    <!-- Score Number  -->
                    <!-- Large Screens  -->
                    <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                    <p
                      class="number mb-0 text-white"
                      :style="{
                        fontSize:
                          (userScore.technical_reasoning / 28) * 25 + 65 + 'px'
                      }"
                    >
                      {{ userScore.technical_reasoning }}
                    </p>
                  </div>
                </div>
              </card>
              <!-- Confluence  -->
              <card
                :height="`${userScore.confluence * 2.5 + 120}px`"
                style="
                border-radius: 0;
                border: 1px solid #ccc;
                min-width: 25%;
                background-color: #c36;
                max-height: 230px;
                min-height: 120px;
              "
                class="my-auto score"
              >
                <div class="d-flex flex-column my-auto">
                  <!-- score title text -->
                  <!-- Medium & larger screens text   -->
                  <p
                    class="mt-1 text-center text-white mb-0"
                    style="font-size: 2rem"
                  >
                    {{ localization("Confluence") }}
                  </p>

                  <div
                    class="
                    d-flex
                    align-items-center align-items-xxl-start
                    justify-content-center justify-content-lg-around
                  "
                  >
                    <!-- First Letter  -->
                    <p class="letter mb-0 text-white">
                      {{ localization("Confluence shortcut") }}
                    </p>
                    <!-- Score Number  -->
                    <!-- Large Screens  -->
                    <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                    <p
                      class="number mb-0 text-white"
                      :style="{
                        fontSize: (userScore.confluence / 28) * 25 + 65 + 'px'
                      }"
                    >
                      {{ userScore.confluence }}
                    </p>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
        <!-- //? start part one -->

        <!-- //// start part two -->
        <div v-loading="$store.getters['user/userLoad']">
          <!-- hideScoreAndInstruction is for importing this component in edit profile dialog at users page  -->
          <card v-if="!hideScoreAndInstructions">
            <div class="new_wrapper_of_use_patterns">
              <div class="mb-3" v-if="careerMatchBoxes[0]">
                <div
                  class="cms_strategy_wrapper"
                  v-html="careerMatchBoxes[0].body"
                ></div>
              </div>
              <div>
                <button class="start_ins_button" @click="handleStepOne">
                  {{ localization("Start") }}
                </button>
              </div>
            </div>
          </card>

          <!-- Tasks List  -->

          <!-- // start Large Screens  -->
          <div class="handle_actt" v-if="step1">
            <span class="handling_text"
              >{{ localization("Add job instructions animation") }}
            </span>
          </div>
          <server-table
            class="hidden-sm-only hidden-xs-only"
            :count="careerMatchTasksCount"
            :DataItems="careerMatchTasks"
            @Refresh="Refresh"
            @reset="filter = {}"
            :filter="filter"
            :title="'Tasks'"
            hidden_export_import
            admin_permission="admin_tasks"
            edit_permission="add_tasks"
            @selection="selections"
          >
            <!-- search & filter fields  -->
            <template #filter>
              <!-- search by topic  -->
              <el-select
                v-model="filter.topic_id"
                clearable
                :placeholder="`${localization('Topic')}`"
                class="mt-2 select-default w-100"
              >
                <el-option
                  v-for="(option, index) in careerMatchTasksTopics"
                  :key="index"
                  :label="option.name"
                  :value="option.id"
                ></el-option>
              </el-select>
            </template>

            <!-- Tasks Table  -->
            <template #columns>
              <!-- Select Users -->
              <el-table-column type="selection" width="50"></el-table-column>
              <!-- Task Name  -->
              <el-table-column
                prop="name"
                sortable
                :label="`${localization('Name')}`"
              ></el-table-column>
              <!-- Task Topic -->
              <el-table-column
                prop="topic_name"
                sortable
                :label="`${localization('Topic')}`"
                class="d-none d-md-block"
              ></el-table-column>

              <!-- //? Task Actions  -->
              <el-table-column prop="" label="_">
                <template slot-scope="scope">
                  <div class="td_actions flex justify-content-end">
                    <!-- Edit Task Button  -->
                    <a
                      v-if="hasPermission('edit_tasks')"
                      v-tooltip.top-center="`${localization('edit job')}`"
                      @click="open_task_dialog(2, scope.row)"
                      class="btn btn-warning btn-simple btn-link"
                    >
                      <i class="fa fa-edit"></i
                    ></a>
                    <!-- show task button  -->
                    <router-link
                      v-if="hasPermission('show_tasks')"
                      :to="`/edit-career-match-task/${scope.row.id}`"
                      v-tooltip.top-center="`${localization('show job')}`"
                      class="btn btn-info btn-link btn-xs"
                    >
                      <i class="fa fa-eye"></i
                    ></router-link>
                    <!-- Delete Task Button  -->
                    <a
                      v-if="hasPermission('edit_tasks')"
                      v-tooltip.top-center="`${localization('Delete job')}`"
                      @click="remove(scope.row.id)"
                      class="btn btn-danger btn-simple btn-link"
                    >
                      <i class="fa fa-times"></i
                    ></a>
                  </div>
                </template>
              </el-table-column>
            </template>

            <!-- Add Task Button  -->
            <template #action>
              <div
                style="position:relative"
                @click="open_add_task_dialog(1, {})"
                wide
                class="text-capitalize"
              >
                <el-icon class="el-icon-plus"></el-icon>
                {{ localization("add job") }}
                <!-- {{ localization("Add personal strategy") }} -->
              </div>
            </template>
            <template #another_action>
              <button
                class="border-0 text-dark d-block "
                style="background: transparent"
                :disabled="selected.length == 0"
                @click="printTasks"
              >
                <el-icon class="el-icon-print"></el-icon>
                {{ localization("Print Tasks") }}
              </button>
            </template>
          </server-table>
          <!-- // end Large Screens  -->

          <!-- // start Small & medium Screens  -->
          <server-table
            class="hidden-md-only hidden-lg-only hidden-xl-only"
            :count="careerMatchTasksCount"
            :DataItems="careerMatchTasks"
            @Refresh="Refresh"
            @reset="filter = {}"
            :filter="filter"
            :title="'Tasks'"
            hidden_export_import
            admin_permission="admin_tasks"
            edit_permission="add_tasks"
            @selection="selections"
          >
            <!-- search & filter fields  -->
            <template #filter>
              <!-- search by topic  -->
              <el-select
                v-model="filter.topic_id"
                clearable
                :placeholder="`${localization('Topic')}`"
                class="mt-2 select-default w-100"
              >
                <el-option
                  v-for="(option, index) in careerMatchTasksTopics"
                  :key="index"
                  :label="option.name"
                  :value="option.id"
                ></el-option>
              </el-select>
            </template>

            <!-- Tasks Table  -->
            <template #columns>
              <!-- Select Users -->
              <el-table-column type="selection" width="50"></el-table-column>
              <!-- Task Name  -->
              <el-table-column prop="name" :label="`${localization('Name')}`">
                <template slot-scope="scope">
                  <div
                    class="
                  task_user_profile_wrapper
                    d-flex
                    flex-column flex-sm-row
                    justify-content-sm-between
                    align-items-sm-center
                  "
                  >
                    {{ localization("Name") }}: {{ scope.row.name }}

                    <br />
                    {{ localization("Topic") }}: {{ scope.row.topic_name }}
                    <div>
                      <!-- Edit Task Button  -->
                      <a
                        v-if="hasPermission('edit_tasks')"
                        v-tooltip.top-center="
                          `${localization('Edit personal strategy')}`
                        "
                        @click="open_task_dialog(2, scope.row)"
                        class="btn btn-warning btn-simple btn-link"
                      >
                        <i class="fa fa-edit"></i
                      ></a>
                      <!-- show task button  -->
                      <router-link
                        v-if="hasPermission('show_tasks')"
                        :to="`/edit-career-match-task/${scope.row.id}`"
                        v-tooltip.top-center="
                          `${localization('Show personal strategy')}`
                        "
                        class="btn btn-info btn-link btn-xs"
                      >
                        <i class="fa fa-eye"></i
                      ></router-link>
                      <!-- Delete Task Button  -->
                      <a
                        v-if="hasPermission('edit_tasks')"
                        v-tooltip.top-center="
                          `${localization('Delete personal strategy')}`
                        "
                        @click="remove(scope.row.id)"
                        class="btn btn-danger btn-simple btn-link"
                      >
                        <i class="fa fa-times"></i
                      ></a>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </template>

            <!-- Add Task Button  -->
            <template #action>
              <div
                @click="open_task_dialog(1, {})"
                wide
                class="text-capitalize"
              >
                <el-icon class="el-icon-plus"></el-icon>
                {{ localization("add job") }}
                <!-- {{ localization("Add personal strategy") }} -->
              </div>
            </template>
            <template #another_action>
              <button
                class="border-0 text-dark d-block "
                style="background: transparent"
                :disabled="selected.length == 0"
                @click="printTasks"
              >
                <el-icon class="el-icon-print"></el-icon>
                {{ localization("Print Tasks") }}
              </button>
            </template>
          </server-table>
          <!-- // end Small & medium Screens  -->

          <!-- Next And Back Buttons -->
          <div
            class="team_routes_wrapper"
            :class="{ 'd-flex flex-row-reverse': isRtl() }"
          >
            <router-link class="back_link" to="/career-match-questions">
              <i class="fa fa-arrow-left back_icon mr-1"></i>
              {{ localization("back") }}
            </router-link>
          </div>
        </div>

        <!-- //  start main add or edit job  -->
        <el-dialog
          :title="
            this.type == 1
              ? `${localization('add job')}`
              : `${localization('edit job')}`
          "
          :visible.sync="show_task_dialog"
          width="50%"
          top="4vh"
          append-to-body
          :close-on-click-modal="false"
          :class="{ 'text-right ': isRtl() }"
        >
          <form class="add_edit_task_form" @submit.prevent="save_task">
            <!-- start name  -->
            <div class="every_form_group" :class="{ 'with_rtl ': isRtl() }">
              <div class="is_ints">
                <label class="d-block form_label is_ints_2">
                  <span class=" span_container">{{
                    localization("Name Your Job")
                  }}</span>
                </label>
              </div>
              <input
                type="text"
                :class="{
                  error_input: !$v.name.required && $v.name.$dirty
                }"
                class="d-block form_input"
                v-model="name"
              />
              <div class="validation_error">
                <span
                  v-if="!$v.name.required && $v.name.$dirty"
                  class="text_validation"
                >
                  Please this field is required
                </span>
              </div>
            </div>
            <!-- end name  -->
            <!-- start description  -->
            <div class="every_form_group" :class="{ 'with_rtl ': isRtl() }">
              <div
                class="in_task_choose_photo"
                :class="{ 'with_rtl ': isRtl() }"
              >
                <div class="is_ints with_dec">
                  <label class="d-block form_label">
                    <span class="span_container d-block">{{
                      localization("Type in, or copy and paste(career match)")
                    }}</span>
                    <span class="span_container d-block">{{
                      localization("upload images(career match)")
                    }}</span>
                  </label>
                </div>
                <div class="main_choose_photo">
                  <input
                    @change="handle_upload_file_img($event)"
                    class="d-none"
                    id="choose_photo_description"
                    type="file"
                    accept="image/apng, image/jpeg, image/png, image/webp"
                  />
                  <label
                    :class="{ processing: processing }"
                    class="label_custom_img"
                    for="choose_photo_description"
                    ><span v-if="!processing">{{
                      localization("Extract from photo")
                    }}</span>
                    <div class="loader_ocr" v-if="processing"></div>
                  </label>
                </div>
              </div>
              <textarea
                :class="{
                  error_input: !$v.description.required && $v.description.$dirty
                }"
                class="d-block form_input with_textarea"
                v-model="description"
              ></textarea>

              <div class="validation_error">
                <span
                  v-if="!$v.description.required && $v.description.$dirty"
                  class="text_validation"
                >
                  Please this field is required
                </span>
              </div>
            </div>
            <!-- start Topic -->
            <div class="every_form_group" :class="{ 'with_rtl ': isRtl() }">
              <div class="is_ints with_dec">
                <label class="d-block form_label">
                  <span class="span_container ">{{
                    localization("Select Your job's topic")
                  }}</span>
                </label>
              </div>
              <el-select
                v-model="topic_id"
                clearable
                :placeholder="`${localization('Topic')}`"
                style="width: 100%"
                :class="{
                  error_input: !$v.topic_id.required && $v.topic_id.$dirty,
                  'text-right ': isRtl()
                }"
              >
                <el-option
                  v-for="(item, index) in careerMatchTasksTopics"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
              <div class="validation_error">
                <span
                  v-if="!$v.topic_id.required && $v.topic_id.$dirty"
                  class="text_validation"
                >
                  Please this field is required
                </span>
              </div>
            </div>
            <!-- end  Topic -->
            <div class="form_buttons_container">
              <button
                class="form_cancel_button form_save_button text-center d-block "
                @click="close_task_dialog"
              >
                {{ localization("Cancel") }}
              </button>
              <button
                class="
              text-center
              d-block
              text-capitalize
              form_save_button
            "
              >
                {{ localization("Save") }}
              </button>
            </div>
          </form>
        </el-dialog>
        <!-- //  end main add or job task  -->
      </div>
    </div>

    <div class="container-fluid mb-2 hide_me_printable">
      <el-alert
        class="mb3 mt-2 text-center"
        v-if="!hasPermission('access_lci')"
        type="error"
        show-icon
        :closable="false"
        effect="dark"
      >
        <template>
          <h5 class="my-0" style="margin-bottom: 2px !important">
            {{
              localization("update your plan") != false
                ? localization("update your plan")
                : "Kindly update your plan to access this page"
            }}
            <router-link
              to="/edit-user"
              class="text-white text-uppercase renewLink"
            >
              {{ localization("Renew Subscription") }}
            </router-link>
          </h5>
        </template>
      </el-alert>
    </div>
  </div>
</template>

<script>
import { Card, Table as LTable } from "src/components/index";
import Swal from "sweetalert2";
import ServerTable from "../../components/DataTablePagination.vue";
import "element-ui/lib/theme-chalk/display.css";
import { required } from "vuelidate/lib/validators";
import LAlert from "src/components/Alert";
export default {
  name: "index",
  components: {
    Card,
    LTable,
    ServerTable,
    LAlert
  },
  props: {
    hideScoreAndInstructions: null
  },
  data() {
    return {
      isStepNumber: 1,
      processing: false,
      name: null,
      description: null,
      topic_id: null,
      task_id: null,
      task: {
        name: null,
        description: "",
        topic_id: null
      },
      test_val: null,
      filter: {},
      task_dialog: false,
      type: null,
      description_photo: null,
      rules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        description: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        topic_id: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ]
      },
      show_task_dialog: false,
      imagePreview: null,
      reader: new FileReader(),

      myOptions: {
        useKeyboardNavigation: true,
        labels: {
          buttonSkip: "Skip tour",
          buttonPrevious: "Previous",
          buttonNext: "Next",
          buttonStop: "Finish"
        }
      },
      isHide: false,
      step1: false,
      // for collapse
      activeName: "all",
      selectedItem: [],
      authUserData: {},
      careerMatchBoxes: null,
      selected: [],
      selected_panel: [],
      panel: [`Precision`, `Sequence`, "Technical Reasoning", "Confluence"],
      strategies: [],
      careerMatchBoxes: null
    };
  },
  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },

    taskInstructions() {
      return this.$store.getters["user/taskInstructions"]
        ? this.$store.getters["user/taskInstructions"][0].body
        : "";
    },
    how_to_use_your_patterns() {
      return this.$store.getters["user/get_how_to_use_your_patterns"];
    },
    user2: function() {
      return this.$store.state.user.user;
    },
    userScore() {
      return this.$store.getters["user/score"];
    },
    // Start Tasks At Work
    careerMatchTasks() {
      return this.$store.getters["user/getCareerMatchTasks"];
    },
    careerMatchTasksCount() {
      return this.$store.getters["user/getCareerMatchTasksCount"];
    },
    careerMatchTasksTopics() {
      return this.$store.getters["user/getCareerMatchTasksTopics"];
    },
    careerMatchStatementsQuestions() {
      return this.$store.getters["user/getCareerMatchStatementsQuestions"];
    },
    // All Tasks
    getUserCareerTasks() {
      return this.$store.getters["user/getUserNewCareerTasks"];
    }
  },
  created() {
    this.reader.onload = () => {
      this.imagePreview = this.reader.result;
    };
    // this.handlerRefresh();
  },
  mounted() {
    this.Refresh();
    //
    this.$store.dispatch("user/handlerUserCareerMatchPageTwo").then(res => {
      this.careerMatchBoxes = res.career_match_use_your_pattern;
    });
    // this.$store.dispatch("user/handlerUserStrategyBoxes")
    this.$store.dispatch("user/handlerUserCareerMatchBoxes").then(res => {
      this.careerMatchBoxes = res.career_match;
    });
  },
  methods: {
    handleStepOne() {
      this.step1 = true;
    },
    handlerTourFun() {
      this.isHide = true;
    },
    Refresh(query) {
      // this.$store.dispatch("user/handlerHowToUseYourPatterns");
      // handlerWorkTasks
      this.$store.dispatch("user/handlerCareerMatchTasks", {
        id: this.id,
        query: query
      });
      // this.$store.dispatch("user/handlerCareerMatchStatementsQuestions", {
      //   query: query
      // });
      this.$store.dispatch("user/handlerCareerMatchTopics");
    },
    open_add_task_dialog(type) {
      this.type = type;
      this.show_task_dialog = true;
      this.step1 = false;
    },
    open_task_dialog(type, taskData) {
      this.task = { ...taskData };
      ///// set values in task requirements
      this.name = taskData.name;
      this.description = taskData.description;
      this.topic_id = taskData.topic_id;
      this.task_id = taskData.id;
      this.type = type;
      this.show_task_dialog = true;
      // this.task_dialog = true;
    },
    close_task_dialog() {
      this.show_task_dialog = false;
      this.name = null;
      this.description = null;
      this.topic_id = null;
      this.$v.$reset();
      this.step1 = false;
    },
    handle_upload_file_img(e) {
      this.processing = true;

      this.description_photo = e.target.files[0];
      let payload = new FormData();
      payload.append("photo", e.target.files[0]);
      // this.reader.readAsDataURL(this.description_photo);
      this.$store
        .dispatch("user/get_ocr_photo_description", payload)
        .then(response => {
          this.description = response.photo_description;
          this.processing = false;
          Swal.fire({
            title: "",
            icon: "success",
            text: `${this.localization("Check Extracted Photo")}`,
            type: "success",
            confirmButtonText: `${this.localization("Ok")}`,
            confirmButtonClass: "btn btn-success ",
            buttonsStyling: false
          });
        });
    },
    remove(id) {
      Swal.fire({
        title: "",
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success ",
        cancelButtonClass: "btn btn-danger ",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("user/handleDeleteCareerMatchTask", id)
            .then(_ => {
              this.Refresh(null);
              Swal.fire({
                title: "",
                icon: "success",
                type: "success",
                text: `${this.localization("Deleted")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
            });
        }
      });
    },
    save_task() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("error");
      } else {
        if (this.type == 1) {
          this.$store
            .dispatch("user/handleAddrCareerMatchTask", {
              query: {
                name: this.name,
                description: this.description,
                topic_id: this.topic_id
              }
            })
            .then(_ => {
              this.step1 = false;
              this.show_task_dialog = false;
              this.$v.$reset();
              Swal.fire({
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
              this.Refresh({});
              // this.$router.push(`/edit-career-match-task/${this.task_id}`);
            })
            .catch(_ => {
              this.show_task_dialog = false;
            });
        } else {
          this.$store
            .dispatch("user/handleEditCareerMatchTask", {
              query: {
                name: this.name,
                description: this.description,
                topic_id: this.topic_id
              },
              id: this.task_id
            })
            .then(_ => {
              this.step1 = false;
              this.show_task_dialog = false;
              this.$v.$reset();
              Swal.fire({
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
              this.Refresh({});
              this.$router.push(`/edit-career-match-task/${this.task_id}`);
            })
            .catch(_ => {
              this.show_task_dialog = false;
            });
        }
      }
    },
    switchBetweenSteps(num) {
      this.isStepNumber = num;
    },
    selections(selections) {
      this.selected = Array.from(selections, el => el.id);

      // this.selectedInst.users = Array.from(selections, el => el.id);
    },
    // getUserCareerTasks
    printTasks() {
      let payload = {
        tasks: this.selected
      };
      this.$store.dispatch("user/handlePrintCareerTasks", payload).then(() => {
        window.print();
        this.Refresh();
      });
    },
    getHint(key, decode, score, item) {
      var type = false;

      if (score >= 7 && score < 18) {
        type = "Forge";
      } else if (score >= 18 && score < 25) {
        type = "Intensify";
      }
      if (decode) {
        type = item[0] ? item[0].classification : type;
        if (type == "Use First") {
          if (key == "Sequence") {
            return `${this.localization("Sequence - Use First")}`;
          } else if (key == "Precision") {
            return `${this.localization("Precision - Use First")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("TR - Use First")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Confluence - Use First")}`;
          }
        } else if (type) {
          // console.log(score);
          //console.log(type);
          // Intensify
          if (type == "Intensify" && key == "Sequence") {
            return `${this.localization("Sequence Intensify")}`;
          } else if (type == "Intensify" && key == "Precision") {
            return `${this.localization("Precision Intensify")}`;
          } else if (type == "Intensify" && key == "Technical Reasoning") {
            return `${this.localization("TR Intensify")}`;
          } else if (type == "Intensify" && key == "Confluence") {
            return `${this.localization("Confluence Intensify")}`;
          }
          if (type == "Forge" && key == "Sequence") {
            return `${this.localization("Sequence Forge")}`;
          } else if (type == "Forge" && key == "Precision") {
            return `${this.localization("Precision Forge")}`;
          } else if (type == "Forge" && key == "Technical Reasoning") {
            return `${this.localization("TR Forge")}`;
          } else if (type == "Forge" && key == "Confluence") {
            return `${this.localization("Confluence Forge")}`;
          }
        } else {
          //// if pattern exist and score between 25 &b 35
          if (key == "Sequence") {
            return `${this.localization("Sequence - Use First")}`;
          } else if (key == "Precision") {
            return `${this.localization("Precision - Use First")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("TR - Use First")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Confluence - Use First")}`;
          }
        }
      } else {
        // If no pattern //// and score between 7-18 -- type now FORGE
        if (type && type == "Forge") {
          if (key == "Sequence") {
            return `${this.localization("Sequence No Term")}`;
          } else if (key == "Precision") {
            return `${this.localization("Precision No Term")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("TR No Term")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Confluence No Term")}`;
          }
        } else if (type && type == "Intensify") {
          if (key == "Sequence") {
            return `${this.localization("Sequence Intensify")}`;
          } else if (key == "Precision") {
            return `${this.localization("Precision Intensify")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("TR Intensify")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Confluence Intensify")}`;
          }
        } else {
          if (key == "Sequence") {
            return `${this.localization("Sequence tether")}`;
          } else if (key == "Precision") {
            return `${this.localization("Precision tether")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("TR tether")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Confluence tether")}`;
          }
        }
      }
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },
    getProgressValue(key) {
      switch (key) {
        case "Precision":
          return this.userScore.precision;
        case "Technical Reasoning":
          return this.userScore.technical_reasoning;
        case "Confluence":
          return this.userScore.confluence;
        case "Sequence":
          return this.userScore.sequence;
      }
    }
  },
  validations: {
    name: {
      required
    },
    description: {
      required
    },
    topic_id: {
      required
    }
  }
};
</script>

<style scoped lang="scss">
.wrap_instructions {
  padding: 10px 0;
  border-top: 1px solid #ccc;
  .title_inst {
    margin-bottom: 10px;
  }
  .text_inst {
    line-height: 1.7;
  }
}
.moveUp-enter-active {
  animation: moveUp 0.5s ease-in;
}
@keyframes moveUp {
  0% {
    // transform: translateY(400px);
    // opacity: 0;
    transform: scale(0);
  }
  100% {
    // transform: translateY(0);
    // opacity: 1;
    transform: scale(1);
  }
}
.which_steps_container {
  .steps_content_wrapper {
    overflow: hidden;
    padding: 15px;
    .content_numbers {
      // box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2), 0 0 5px -2px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 700px;
      height: 330px;
      min-height: 330px;
      max-height: 330px;
      border-radius: 10px;
      padding: 20px;
      &.pp_one {
        cursor: pointer;
      }
      .img_previous {
        width: 100%;
        max-height: 100%;
        display: block;
      }
    }
  }
  .steps_buttons_wrapper {
    // background-color: #ccc;
    display: flex;
    gap: 10px;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 700px;
    .btn_number {
      background-color: #e0e0e0;
      border: none;
      padding: 10px;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      transition: 0.3s;
      &.is_active,
      &:hover {
        background-color: #24ccef;
      }
    }
  }
}
.new_wrapper_of_use_patterns {
  .img_container {
    display: none;
    .img {
      width: 100%;
      display: block;
    }
  }
  .personalize_title {
    display: flex;
    gap: 8px;
    &.is_rtl {
      flex-direction: row-reverse;
    }
  }
  .want_break_word {
    p {
      span {
        word-wrap: break-word;
        white-space: pre-wrap !important;
      }
    }
  }
}
.team_routes_wrapper {
  display: flex;
  gap: 10px;
  // justify-content: end;
  flex-wrap: wrap;
  margin-top: 20px;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  .back_link {
    color: #777;
    background-color: #eee;
    padding: 8px 10px;
    display: block;
    width: fit-content;
    margin: 10px 0;
    border-radius: 8px;
    transition: 0.3s;
    &:hover {
      background-color: #11bfe3;
      color: #fff;
    }
  }
}
// ::-webkit-scrollbar {
//   width: 5px;
// }
// ::-webkit-scrollbar-thumb {
//   background-color: $main-color;
//   border-radius: 10px;
// }
.lds-ring {
  display: inline-block;
  position: relative;
  width: 35px;
  height: 35px;
  margin-top: 5px;
  background: #11bfe3;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 35px;
  height: 35px;
  // margin: 8px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 0.7s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.renewLink {
  text-decoration: underline;
}
.text-align {
  text-align: center;
}

/* mobile score progress design */

::v-deep .precision {
  margin: 5px;
  display: inline;
  background-color: #3c3;
}

::v-deep .technical_reasoning {
  display: inline;
  margin: 5px;

  background-color: #f93;
}

::v-deep .sequence {
  display: inline;
  margin: 5px;

  background-color: #39c;
  position: relative;
  transition: 0.5s;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 1.5px;
    top: 100%;
    background-color: #39c;
  }
}

::v-deep .confluence {
  display: inline;
  margin: 5px;

  background-color: #c36;
}

::v-deep .el-dialog__header {
  text-transform: capitalize;
}

.label {
  border-radius: 50%;
  margin: 0.5rem;
  width: 3rem;
  height: 3rem;
  text-align: center;
  font-size: 1rem;
  line-height: 2.8;
  color: white;
}

.letter {
  font-size: 40px;
}
.swal2-container.swal2-bottom,
.swal2-container.swal2-center,
.swal2-container.swal2-top {
  grid-template-columns: auto minmax(0, 1fr) auto;
  z-index: 100000;
}

.name {
  font-weight: 300;
  font-size: 19px;
  line-height: 1.2;
  padding-left: 0;
  color: white;
}

.border {
  border: 2px solid white !important;
}

.number {
  line-height: 1;
}

.row {
  margin-top: 0;
}

.v-expansion-panel--active > button {
  background-color: #757575 !important;
  color: #fff;
}

.v-expansion-panel--active > .theme--light .v-icon {
  color: #fff !important;
}

.child.v-expansion-panel--active > button {
  background-color: #26a1da !important;
  color: #fff;
}

.v-slider--horizontal .v-slider__track-container {
  height: 10px !important;
}

// ? dialog style
::v-deep .el-dialog {
  @media (min-width: 320px) and (max-width: 480px) {
    width: 90% !important;
  }
  @media (min-width: 480px) and (max-width: 767px) {
    width: 70% !important;
  }
}
.dialog-footer {
  display: flex;
  justify-content: end;
  align-items: center;
  @media (min-width: 320px) and (max-width: 767px) {
    justify-content: center;
  }
}

//? label and choose photo
::v-deep .el-form-item__label {
  margin-bottom: 0;
  float: left;
  &::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
}

// wrap word in task and in user profile
.task_user_profile_wrapper {
  word-break: break-word;
  white-space: pre-wrap;
}

.add_edit_task_form {
  .every_form_group {
    margin-bottom: 32px;
    .form_label {
      color: #606266;
      font-size: 14px;
      // text-transform: capitalize;
      padding-left: 10px;
      margin-bottom: 10px;
      // width: fit-content;
      .span_container {
        position: relative;
        word-break: break-word;
        max-width: 98%;
        &::before {
          content: "*";
          color: #f56c6c;
          position: absolute;
          // left: 0;
          left: -10px;
          top: -3px;
        }
      }
    }
    .form_input {
      color: #606266;
      width: 100%;
      border: 1px solid #c0c4cc;
      outline: none;
      transition: 0.3s;
      border-radius: 4px;
      font-size: inherit;
      height: 40px;
      line-height: 40px;
      outline: 0;
      padding: 0 15px;
      &.with_textarea {
        height: 150px !important;
        line-height: 1.7 !important;
        padding: 10px 15px;
        resize: none;
        word-break: break-word;
      }
      &:focus {
        border-color: #11bfe3;
      }
      // &.error_input {
      //   border-color: #f56c6c;
      // }
    }
    .validation_error {
      color: #f56c6c;
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
      .text_validation {
      }
    }
    &.with_rtl {
      .form_input {
        text-align: right;
        &.with_textarea {
          text-align: right;
        }
      }
    }
  }
  .form_buttons_container {
    display: flex;
    justify-content: end;
    align-items: center;
    @media (min-width: 320px) and (max-width: 767px) {
      justify-content: center;
    }
    .form_save_button {
      transition: 0.3s all ease-in-out;
      background-color: #00563f;
      color: #fff;
      opacity: 0.7;
      max-width: 180px;
      padding: 8px 20px;
      font-size: 14px;
      border-radius: 4px;
      font-weight: 500;
      border: 1px solid #00563f;
      width: 180px;
      &.form_cancel_button {
        margin-right: 10px;
        border: 1px solid #fb404b;
        background-color: #fb404b;
      }
    }
  }
  .in_task_choose_photo {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .main_choose_photo {
      margin-bottom: 10px;
      .label_custom_img {
        padding: 8px 15px;
        border-radius: 5px;
        cursor: pointer;
        color: #fff;
        margin-bottom: 10px;
        transition: 0.3s all;
        background-color: #11bfe3;
        background-color: #00563f;
        min-width: 144px;
        position: relative;
        height: 37px;
        &:hover {
          opacity: 0.8;
        }
        &.processing {
          pointer-events: none;
        }
        .loader_ocr {
          position: absolute;
          width: 25px;
          height: 25px;
          margin-bottom: 7px;
          left: calc(50% - 12.5px);
          top: 15%;
          border: 3px solid #fff;
          border-radius: 50%;
          animation: rotation 0.7s linear infinite;
          border-color: #fff #fff #fff transparent;
        }
      }
    }
    &.with_rtl {
      flex-direction: row-reverse;
    }
  }

  $width: 150px;
  .is_ints {
    // display: none;
    position: relative;
    .give {
      // display: none;
      background: #00563f;
      color: #fff;
      position: absolute;
      top: -70px;
      left: 25%;
      padding: 7px;
      min-height: 60px;
      border-radius: 8px;
      &::before {
        position: absolute;
        content: "";
        height: 29px;
        background: #00563f;
        width: 3px;
        top: 90%;
        left: 50%;
      }
      .is_next {
        margin-top: 7px;
        display: flex;
        justify-content: end;
        padding: 3px 12px;
        border-radius: 3px;
        .next {
          background: #fff;
          color: #00563f;
          padding: 5px;
          border-radius: 3px;
          min-width: 70px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
    &.with_dec {
      .give {
        left: 37%;
        min-width: 400px;
        &::before {
          height: 34px;
        }
        .is_next {
          justify-content: space-between;
        }
      }
    }
    &.is_save {
      .give {
        left: 37%;
        min-width: 100px;
        top: auto;
        bottom: -75px;
        display: flex;
        justify-content: center;
        align-items: center;
        &::before {
          top: -16px;
          height: 29px;
        }
      }
    }
  }
}
.handle_actt {
  position: relative;
  height: 20px;

  .handling_text {
    background: #00563f;
    color: #fff;
    position: absolute;
    right: 9px;
    // top: -57px;
    bottom: 100%;
    padding: 10px;
    min-height: 52px;
    display: block;
    border-radius: 8px;
    min-width: 300px;
    max-width: 400px;
    &::before {
      position: absolute;
      content: "";
      height: 25px;
      width: 3px;
      top: 100%;
      background: #00563f;
      right: 72px;
    }
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.start_ins_button {
  transition: 0.3s all ease-in-out;
  background-color: #00563f;
  color: #fff;
  opacity: 0.7;
  max-width: 180px;
  padding: 8px 20px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
  border: 1px solid #00563f;
  width: 180px;
}

// Start the_main_container_for_print_all_pdf
.the_main_container_for_print_all_pdf {
  display: none;
  @media print {
    display: block !important;
  }
  .main_container {
    .main_auth_user_info {
      text-align: center;
      display: block;
      // display: none;
      @media print {
        display: block !important;
      }
      .n_name {
        font-size: 24px;
        font-weight: bold;
        text-transform: capitalize;
      }
      .n_email {
        font-size: 22px;
      }
      .classification_classification {
        padding: 0 36px;
        min-width: 180px;
        border-radius: 12px;
        background-color: #00563f;
        height: 45px;
        display: flex;
        align-items: center;
        width: fit-content;
        color: #fff;
        margin: 10px auto;
      }
      // Below Score Cards
    }
    .the_patterns_with_new_theme {
      position: relative;
      .new_theme_pattern_content {
        // display: none;
        display: block;
        @media print {
          display: block !important;
        }
        padding: 0 10px;
        .main_content_container {
          border-left: 2px solid;
          border-right: 2px solid;
          border-bottom: 2px solid;
          text-align: center;
          padding: 10px 5px;
          &.with_sequence {
            border-color: #39c;
          }
          &.with_precision {
            border-color: #3c3;
          }
          &.with_technical_reasoning {
            border-color: #f93;
          }
          &.with_confluence {
            border-color: #c36;
          }
          .ti {
            // color: #555;
            font-size: 15px;
          }
          .te {
            color: #777;
            font-size: 13px;
          }
        }
      }
    }
    .wrapper_for_use_first {
      display: block;
      // display: none;
      @media print {
        display: block !important;
      }
      .main_co {
        background-color: #eee;
        display: flex;
        justify-content: center;
        gap: 10px;
        color: #7d7979;
        padding: 15px;
        .a_one {
          &.with_title {
            color: #555;
          }
        }
        .a_two {
          border-left: 3px solid #777;
          border-right: 3px solid #777;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
    .main_collapse_title_wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      padding: 0 10px;
      .every_collapse_title {
        @media (min-width: 320px) and (max-width: 480px) {
          font-size: 18px;
        }
        @media (min-width: 320px) and (max-width: 350px) {
          font-size: 11px;
        }
      }
      .every_collapse_button {
        color: #fff;
        width: 50px;
        height: 50px;
        @media (min-width: 320px) and (max-width: 480px) {
          width: 36px;
          height: 36px;
        }
      }
      &.is_rtl {
        flex-direction: row-reverse;
        padding-right: 10px;
      }
    }
    .main_strategy_container {
      display: flex;
      gap: 4%;
      page-break-inside: avoid;
      @media (min-width: 320px) and (max-width: 991px) {
        flex-wrap: wrap;
      }
      @media print {
        flex-wrap: nowrap;
        margin-top: 40px !important;
        page-break-after: always;
      }
      .cms_strategy_wrapper {
        width: calc(96% / 2);
        padding: 15px;
        color: #fff;
        border-radius: 10px;
        background-color: #00563f;
        min-height: 170px;
        @media (min-width: 320px) and (max-width: 991px) {
          margin-bottom: 20px;
        }
      }
    }
    .set_bg_white_for_share {
      background: #fff;
    }
    .alert_for_collapse {
      font-size: 1.2rem;
      @media (min-width: 320px) and (max-width: 767px) {
        font-size: 1rem;
      }
      /* Define custom class for page break */
      .print-page-break {
        page-break-inside: avoid;
      }
    }
    .el-collapse {
      background: #fff;
    }
    .wrapper_check_speech {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }
    .icons_style {
      // background-color: #eee;
      // box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
      font-size: 14px;
      width: 30px;
      height: 30px;
      // box-shadow: $simple-shadow;
      text-align: center;
      line-height: 30px;
      border-radius: 50%;
      cursor: pointer;
      color: #fff;
      transition: 0.3s;
      &.speech {
        &:hover {
          background-color: #11bfe3;
          color: #fff;
        }
      }
      &.delete {
        &:hover {
          background-color: #fb404b;
          color: #fff;
        }
      }
    }
  }
  .handler_speech_wrapper {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    .icons_style {
      cursor: pointer;
    }
  }
  .handler_strategy_style {
    font-size: 1.2rem;
    border-radius: 4px;
    color: #ffffff;
    padding: 10px 15px;
    line-height: 1.7;

    @media (min-width: 320px) and (max-width: 767px) {
      font-size: 1rem;
    }
  }
}
::v-deep .el-checkbox {
  text-transform: capitalize !important;
  display: flex;
  &.check_box_with_rtl {
    flex-direction: row-reverse;
    margin-right: 0;
    align-items: center;
    gap: 3px;
  }
}

::v-deep .el-checkbox__label {
  word-break: break-word !important;
  white-space: pre-wrap;
  // word-wrap: break-word;
  white-space: normal;
  color: #000;
  padding: 0 7px;
  text-transform: initial;
}
::v-deep .el-checkbox__inner {
  border: 1px solid #000;
}
::v-deep .texst {
  word-break: break-word !important;
  word-wrap: break-word;
  white-space: normal;
  // white-space: pre-wrap;
  text-transform: initial;
  font-size: 1.2rem;
  line-height: 1;
  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 1rem;
  }
}
.v-expansion-panel--active > button {
  background-color: #757575 !important;
  color: #fff;
}

.v-expansion-panel--active > .theme--light .v-icon {
  color: #fff !important;
}

.child.v-expansion-panel--active > button {
  background-color: #26a1da !important;
  color: #fff;
}

.v-slider--horizontal .v-slider__track-container {
  height: 10px !important;
}
::v-deep .el-card__body {
  display: none;
}
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
  .hide_me_printable {
    display: none !important;
  }
  .looping_tasks_here {
    page-break-inside: avoid;
  }
  .print-page-break {
    page-break-after: always;
  }
}
</style>

<style lang="scss">
.main_youtube_html_render_for_tasks {
  .row {
    flex-direction: row-reverse;
    ul {
      text-align: right;
      list-style: none;
      margin-left: 0 !important;
      padding-left: 0 !important;
      li {
        text-align: right;
        position: relative;
        padding-right: 17px;
        text-align: right;
        &::before {
          position: absolute;
          content: "";
          background-color: #706f6f;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          right: 0;
          top: 7px;
        }
      }
    }
  }
}

.new_wrapper_of_use_patterns {
  .want_break_word {
    p {
      span {
        word-wrap: break-word;
        white-space: pre-wrap !important;
      }
    }
  }
}
// Start the_main_container_for_print_all_pdf
.the_main_container_for_print_all_pdf {
  .el-collapse-item__header {
    padding: 2.5rem 0;
  }
}
</style>
